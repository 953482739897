
/*Responsive Grid*/
.row::after {
  content: "";
  clear: both;
  display: table;
}

[class*="col-"] {
  float: left;
  padding: 15px;
  box-sizing: border-box;
}
[class*="col-"]::after {
  clear:both;
}
  /* For mobile phones: */
  [class*="col-"] {
    width: 100%;
  }
  @media only screen and (min-width: 600px) {
    /* For tablets: */
    .col-sm-1 {width: 8.33%;}
    .col-sm-2 {width: 16.66%;}
    .col-sm-3 {width: 25%;}
    .col-sm-4 {width: 33.33%;}
    .col-sm-5 {width: 41.66%;}
    .col-sm-6 {width: 50%;}
    .col-sm-7 {width: 58.33%;}
    .col-sm-8 {width: 66.66%;}
    .col-sm-9 {width: 75%;}
    .col-sm-10 {width: 83.33%;}
    .col-sm-11 {width: 91.66%;}
    .col-sm-12 {width: 100%;}
  }
@media only screen and (min-width: 768px){
.col-1 {width: 8.33%;}
.col-2 {width: 16.66%;}
.col-3 {width: 25%;}
.col-4 {width: 33.33%;}
.col-5 {width: 41.66%;}
.col-6 {width: 50%;}
.col-7 {width: 58.33%;}
.col-8 {width: 66.66%;}
.col-9 {width: 75%;}
.col-10 {width: 83.33%;}
.col-11 {width: 91.66%;}
.col-12 {width: 100%;}
}
@media only screen and (min-width: 1140px){
  .col-lg-1 {width: 8.33%;}
  .col-lg-2 {width: 16.66%;}
  .col-lg-3 {width: 25%;}
  .col-lg-4 {width: 33.33%;}
  .col-lg-5 {width: 41.66%;}
  .col-lg-6 {width: 50%;}
  .col-lg-7 {width: 58.33%;}
  .col-lg-8 {width: 66.66%;}
  .col-lg-9 {width: 75%;}
  .col-lg-10 {width: 83.33%;}
  .col-lg-11 {width: 91.66%;}
  .col-lg-12 {width: 100%;}
  }





.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
