.project-holder{
    margin: 15px;
    border-radius: 2vh;

    min-height: 45vh;
    /* box-shadow:  0 0 18px 2px white,  0 0 8px 2px white inset ; */
    padding:20px;
    display:flex;
    flex-wrap: wrap;
    align-content: flex-start;


}