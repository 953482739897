.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}
.modal-visible {
  display: block;
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}
@media only screen and (min-width: 600px) {
  .modal-content {
    width: 70%;
  }
}
@media only screen and (min-width: 768px) {
    .modal-content {
      width: 50%;
    }
  }
  @media only screen and (min-width: 768px) {
    .modal-content {
      width: 30%;
    }
  }

.modal-close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  /* content:"X"; */
}
.modal-close:hover,
.modal-close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
.modal-links {
  display: flex;
  justify-content: space-around;
}

.project-pic{
    width:50%;

}
.in-dev{
    display:inline;
}
