.project-box{

/* display:grid;

grid-template-columns: auto auto auto; */
overflow:hidden;
border:solid 2px #058d49;
border-radius: 5px;
background-color:#222;
box-shadow: 3px 3px 16px 0 rgba(255, 255, 255, 0.1),3px 3px 15px 0 rgba(255, 255, 255,.19), -2px -2px 6px 0 rgba(50,50,50,.19), -2px -2px 6px 0 rgba(50,50,50,.19);

}
.project-box:hover{
background-color:#333;
/* box-shadow: 6px 6px 16px 0 rgba(168, 161, 161, 0.24),10px 10px 50px 0 rgba(168, 161, 161,.19), -2px -2px 6px 0 rgba(168, 161, 161,.19), -2px -2px 6px 0 rgba(168, 161, 161,.19); */
box-shadow: 6px 6px 16px 0 rgba(3, 255, 171, 0.3),6px 6px 30px 0 rgba(255, 255, 255,.19), -2px -2px 6px 0 rgba(50,50,50,.19), -2px -2px 6px 0 rgba(50,50,50,.19);
border:solid 2px #03ffab;

}

.project-title{
    font-weight:bold;
    display:grid;
    background-color:#005c2e;
    grid-column-start: 1;
    grid-column-end: 4;

    font-size:1.5em;
    text-align: center;
    font-family: var(--header-text);

}
.project-summary{
    display:block;
    font-weight:500;

}
.project-description{
    grid-column-start: 2;
    grid-column-end: 4;
    padding: 10px;
    color:rgb(85, 156, 228);
   
}

.icon-container{
text-align:center;
}
.icon{
    display:inline-block;
    margin:5px;
    transition: all .2s;
    -webkit-transition: all .2s;
}
.icon:hover{
  -webkit-transform: scale(1.2,1.2);
  transform: scale(1.2,1.2);
}
/* Tooltip container */
.tooltip {
    position: relative;
    display: inline-block;


  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1000;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    -webkit-transition: opacity 0.3s;
    transition: opacity 0.3s;
  }
  
  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;

  }


.project-holder{
    margin: 15px;
    border-radius: 2vh;

    min-height: 45vh;
    /* box-shadow:  0 0 18px 2px white,  0 0 8px 2px white inset ; */
    padding:20px;
    display:flex;
    flex-wrap: wrap;
    align-content: flex-start;


}
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}
.modal-visible {
  display: block;
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}
@media only screen and (min-width: 600px) {
  .modal-content {
    width: 70%;
  }
}
@media only screen and (min-width: 768px) {
    .modal-content {
      width: 50%;
    }
  }
  @media only screen and (min-width: 768px) {
    .modal-content {
      width: 30%;
    }
  }

.modal-close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  /* content:"X"; */
}
.modal-close:hover,
.modal-close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
.modal-links {
  display: flex;
  justify-content: space-around;
}

.project-pic{
    width:50%;

}
.in-dev{
    display:inline;
}

#top-arrow-box{
    padding-left:10px;
    padding-right:10px;
  
    color:black;
    font-weight: 600;
background-color:#222;
/* background-color: #005c2e77; */
    border: solid #FFFFFF99 2px;
    border-radius: 10px;
    position:fixed;
    bottom:20px;
    right:20px;
    z-index:1000;
    -webkit-transition: all .2s;
    transition: all .2s;
  }
  #top-arrow-box:hover{
    -webkit-transform:scale(1.1);
            transform:scale(1.1);
    background-color: #050e09;
  border: solid #03ffab 2px;
  }
  #top-arrow-box:before{
    border: solid black 2px;
    content: " ";
    position: absolute;
    z-index: -1;
    top: -4px;
    left: -4px;
    right: -4px;
    bottom: -4px;
    border-radius: 12px;
  
  
  }
  .up-arrow{
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 5px;
    transform: rotate(-135deg) translate(-50%,0%);
    -webkit-transform:  translate(0%,25%) rotate(-135deg);
  }
  #top-arrow-box:hover .up-arrow{
    border:solid #03ffab;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 5px;
    transform: rotate(-135deg) translate(-50%,0%);
    -webkit-transform:  translate(0%,25%) rotate(-135deg);
    
  }

/*Responsive Grid*/
.row::after {
  content: "";
  clear: both;
  display: table;
}

[class*="col-"] {
  float: left;
  padding: 15px;
  box-sizing: border-box;
}
[class*="col-"]::after {
  clear:both;
}
  /* For mobile phones: */
  [class*="col-"] {
    width: 100%;
  }
  @media only screen and (min-width: 600px) {
    /* For tablets: */
    .col-sm-1 {width: 8.33%;}
    .col-sm-2 {width: 16.66%;}
    .col-sm-3 {width: 25%;}
    .col-sm-4 {width: 33.33%;}
    .col-sm-5 {width: 41.66%;}
    .col-sm-6 {width: 50%;}
    .col-sm-7 {width: 58.33%;}
    .col-sm-8 {width: 66.66%;}
    .col-sm-9 {width: 75%;}
    .col-sm-10 {width: 83.33%;}
    .col-sm-11 {width: 91.66%;}
    .col-sm-12 {width: 100%;}
  }
@media only screen and (min-width: 768px){
.col-1 {width: 8.33%;}
.col-2 {width: 16.66%;}
.col-3 {width: 25%;}
.col-4 {width: 33.33%;}
.col-5 {width: 41.66%;}
.col-6 {width: 50%;}
.col-7 {width: 58.33%;}
.col-8 {width: 66.66%;}
.col-9 {width: 75%;}
.col-10 {width: 83.33%;}
.col-11 {width: 91.66%;}
.col-12 {width: 100%;}
}
@media only screen and (min-width: 1140px){
  .col-lg-1 {width: 8.33%;}
  .col-lg-2 {width: 16.66%;}
  .col-lg-3 {width: 25%;}
  .col-lg-4 {width: 33.33%;}
  .col-lg-5 {width: 41.66%;}
  .col-lg-6 {width: 50%;}
  .col-lg-7 {width: 58.33%;}
  .col-lg-8 {width: 66.66%;}
  .col-lg-9 {width: 75%;}
  .col-lg-10 {width: 83.33%;}
  .col-lg-11 {width: 91.66%;}
  .col-lg-12 {width: 100%;}
  }





.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

